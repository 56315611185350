<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select
            :items="itemsPerPageOption"
            label="Show Items"
            dense
            v-model="datatable_options.itemsPerPage"
            style="max-width: 100px"
            hide-details
            single-line
          ></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field
          v-if="!isMobile"
          dense
          autofocus
          outlined
          rounded
          placeholder="Search"
          v-model="search"
          append-icon="mdi-magnify"
          style="max-width: 300px"
          hide-details
          single-line
        ></v-text-field>

        <v-btn icon @click="dialog = !dialog" title="Filter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field
          class="mb-2"
          dense
          clearable
          autofocus
          outlined
          placeholder="Search"
          v-model="search"
          append-icon="mdi-magnify"
          style="max-width: 3000px"
          hide-details
          single-line
        ></v-text-field>
      </div>
      <v-sheet elevation="1" outlined flat>
        <v-data-table
          :options.sync="datatable_options"
          :loading="datatable.loading"
          :loading-text="datatable.loadingText"
          :headers="datatable.columns"
          :items="datatable.data"
          :search="search"
          :hide-default-header="isMobile"
          :server-items-length="datatable.totalRows"
          @page-count="pageCount = $event"
          dense
          class="mb-3 my-0"
        >
          <template v-slot:[`item.no_`]="{ item }" v-if="!isMobile">
            <span>{{ item.item_no_ }}</span>
          </template>
          <template v-slot:[`item`]="{ item }" v-if="isMobile">
            <v-card elevation="4" class="ma-1" outlined>
              <v-card-title class="text-h5 mb-1 pb-1">{{
                item.item_no_
              }}</v-card-title>
              <v-card-subtitle class="my-0 py-0 mb-3">
                {{ item.item_description }} <br />
                {{ item.unit_of_measure_code }} <br />
                {{ item.location_code }} | {{ item.bin_code }} <br />
                {{ item.quantity_base }}
              </v-card-subtitle>
            </v-card>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >s
        </v-data-table>
      </v-sheet>
    </v-card>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title
          ><v-icon>mdi-filter</v-icon> Filter
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" icon small title="Close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="filter_location_code"
            dense
            hide-details
            outlined
            flat
            class="mb-2"
            label="Location Code"
          ></v-text-field>
          <v-text-field
            v-model="filter_bin_code"
            dense
            hide-details
            outlined
            flat
            class="mb-2"
            label="Bin Code"
          ></v-text-field>
          <v-text-field
            v-model="filter_item_no_"
            dense
            hide-details
            outlined
            flat
            class="mb-2"
            label="Item No"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="getData(), (dialog = !dialog)" color="primary"
            >Filter</v-btn
          >
          <v-btn
            @click="
              (filter_location_code = ''),
                (filter_bin_code = ''),
                (filter_item_no_ = ''),
                getData(),
                (dialog = !dialog)
            "
            >Clear</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" title="Close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      elevations: [6, 12, 18],
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20,
      },
      itemsPerPageOption: [20, 50, 100, 500],
      datatable: {
        options: {},
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Location Code", value: "location_code" },
          { text: "Bin Code", value: "bin_code" },
          { text: "Item No.", value: "item_no_" },
          { text: "Item Description", value: "item_description" },
          { text: "Variant Code", value: "variant_code" },
          { text: "UoM Code", value: "unit_of_measure_code" },
          { text: "Zone Code", value: "zone_code" },
          { text: "Base UoM", value: "base_unit_of_measure" },
          { text: "Quantity Base", value: "quantity_base" },
          { text: "Qty. per UoM", value: "qty_per_unit_of_measure" },
        ],
        data: [],
      },
      dialog: null,
      filter_location_code: "",
      filter_bin_code: "",
      filter_item_no_: "",
    };
  },
  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "bincontent/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      formData.append(
        "filter",
        JSON.stringify({
          location_code: this.filter_location_code,
          bin_code: this.filter_bin_code,
          item_no_: this.filter_item_no_,
        })
      );
      this.$axios
        .post("bincontent/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
